*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #f0f0f5 !important;
}

main {
  padding: 0 !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.customLabelContent .fc-icon,
.customLabelContent .fc-datagrid-expander.fc-datagrid-expander-placeholder {
  display: none !important;
}

.fc-timeline-event-harness a {
  border-radius: 8px;
  /* top: 50%;
  bottom: 50%;
  transform: translate(0, 50%); */
}

/* .fc-h-event {
  border: none !important;
} */

.fc-timeline-event {
  padding: 0 !important;
}

/* TODO: Delete all the code below after cleaning the components  */
.taskstable td {
  position: relative;
  padding: 8px;
  vertical-align: middle;
}
.taskstable td:first-child,
.taskstable td:nth-of-type(2) {
  width: 15px;
}
.taskstable td:nth-of-type(3) {
  width: 6%;
}
.taskstable td:nth-of-type(4) {
  width: 47.5%;
}
.taskstable td:nth-of-type(5) {
  width: 18.5%;
}
.taskstable td:nth-of-type(6) {
  width: 3.7%;
}
.taskstable td:nth-of-type(7) {
  width: 12.4%;
}
.taskstable td:nth-of-type(8) {
  width: 7%;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  margin-bottom: 30px;
}
.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

.align-middle {
  display: flex;
  align-items: center;
}

.df-jc-sb {
  display: flex;
  justify-content: space-between;
}

.df-jc-fe {
  display: flex;
  justify-content: flex-end;
}

.lh-fit {
  line-height: 1;
}

.fit {
  width: fit-content;
}

.text-right {
  text-align: right !important;
}

.hide {
  visibility: hidden;
}

.noWrap {
  white-space: nowrap;
}

.MuiDrawer-paper {
  z-index: 900 !important;
}

.MuiAppBar-root {
  /* increase if display header instead of cross button */
  z-index: 901 !important;
}

/* custom tooltip style */

.custom-tooltip-style {
  border: 1px solid rgb(204, 204, 204);
  background-color: white;
  padding: 10px;
}

.custom-tooltip-style p {
  margin-bottom: 4px;
}

.custom-tooltip-item {
  margin-bottom: 6px;
}

.b-sch-event-wrap.b-sch-style-rounded.b-sch-custom-color
  .b-sch-event:not(.b-milestone) {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.8)
  );
}

.b-sch-style-rounded .b-task-percent-bar {
  background-color: #0062ff !important;
  mix-blend-mode: normal !important;
  opacity: 0.5;
}

.b-sch-style-rounded .b-task-percent-bar-outer {
  border-radius: 1em !important;
}
.b-sch-event-wrap.b-sch-style-rounded .b-sch-event:not(.b-milestone) {
  border-radius: 1em !important;
}

.b-sch-event-wrap.b-milestone-wrap .b-fa,
.b-sch-event-wrap.b-milestone-wrap .b-icon {
  color: #1ab394 !important;
}

.b-sch-horizontal .b-sch-style-line .b-sch-event,
.b-sch-horizontal .b-sch-style-dashed .b-sch-event {
  border-width: 4px 0 0 0;
}

.b-sch-event-wrap.b-readonly {
  opacity: 1 !important;
}

.b-resource-info {
  flex: 1 !important;
}

.b-demo-toolbar {
  justify-content: flex-end !important;
  border-bottom: 1px solid #d8d9da;
  /*background-color: #f3f4f5;*/
  color: #4f5964;

  display: flex;
  padding: 0.5em;
  padding-right: 0em;
  align-items: center;
}

.b-sch-event-wrap.b-readonly .b-sch-event-content {
  opacity: 0.9 !important;
}

.b-sch-event-wrap.b-readonly .b-sch-event-content {
  opacity: 0.9 !important;
}

.resource-image {
  width: 4em;
  height: 4em;
  border-radius: 100%;
  margin-right: 1em;
}

.scheduler-avatar {
  width: 2.2em;
  height: 2.2em;
  background: #cce0ff;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.5em;
}

.hideSchedulerEvent {
  background-color: transparent !important;
  background-image: none !important;
  color: transparent !important;
}

.b-tree-cell-inner {
  padding-inline-start: 0 !important;
}

.b-tree-expander {
  margin-left: 0px !important;
}

.bryntum-leaf {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bryntum-subtitle {
  font-size: 12px;
}

.bryntum-resource-timerange-active-phases {
  white-space: nowrap;
}

@media print {
  #printPageButton {
    display: none;
  }
  body {
    background-image: linear-gradient(#ffffff, #ffffff);
  }
  .b-task-percent-bar-outer {
    background: rgba(255, 255, 255, 0.6);
    font-weight: bolder;
  }
}

.b-button.b-secondary:not(.b-tab) {
  color: #0062ff;
}

.tox-statusbar__path > div.tox-statusbar__path-item {
  display: none;
}

.pac-container {
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1300;
}

.pac-item {
  padding: 5px;
  cursor: pointer;
}
